import type * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { Loader2 } from 'lucide-react';
import { cn } from '~/utils/cn';

const buttonVariants = cva(
  'relative inline-flex items-center justify-center overflow-hidden whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'size-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ComponentPropsWithRef<'button'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = ({
  ref,
  className,
  variant,
  size,
  asChild = false,
  children,
  loading,
  ...props
}: ButtonProps) => {
  const Comp = asChild ? Slot : 'button';

  const buttonVariant = buttonVariants({ variant, size, className });

  return (
    <Comp className={cn(buttonVariant)} ref={ref} {...props} disabled={loading || props.disabled}>
      {children}

      {loading ? (
        <span
          className={cn(
            'absolute inset-0 flex items-center justify-center',
            buttonVariant.split(' ').filter((f) => f.startsWith('bg-')) || 'bg-background',
          )}
        >
          <Loader2 className="size-4 animate-spin" size={24} />
        </span>
      ) : null}
    </Comp>
  );
};
Button.displayName = 'Button';

export { Button, buttonVariants };
