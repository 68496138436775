import React, { Suspense } from 'react';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize.js';
import { ClientOnly } from 'remix-utils/client-only';
import { Await, Link, PrefetchPageLinks, useNavigate, useRouteLoaderData } from '@remix-run/react';
import { Avatar } from '~/components/Avatar';
import { Button, buttonVariants } from '~/components/Button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/Dropdown';
import { Bell, InfoIcon, Settings2 } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '~/components/Popover';
import { Skeleton } from '~/components/Skeleton';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/Tooltip';
import type { loader as _cLayoutLoader } from '~/routes/_c';
import type { UserSession } from '~/types';
import { AREA_EMOJI } from '~/utils/area';
import { cn } from '~/utils/cn';
import { formatUser } from '~/utils/user';
import { InboxItemName } from './InboxItemName';
import { ItemEventIcon } from './ItemIcon';
import {
  useInboxItemEvents,
  useNotificationPreferences,
  useNotificationSources,
  usePushNotificationsEnabled,
  ALL,
  filterInboxItemEvent,
} from './use-inbox-item-events';
import type { NotificationSource, InboxItemEvent } from './use-inbox-item-events';
import { getEventBody, getNotificationLink } from './utils';

export const BellSkeleton = (props: React.ComponentPropsWithRef<'div'>) => (
  <Skeleton
    {...props}
    className={cn('aspect-square size-8 min-w-8 animate-pulse rounded-full', props.className)}
  />
);

export function Notifications({
  user,
}: {
  user: UserSession & { socketToken: string };
}) {
  const initialData = useRouteLoaderData<typeof _cLayoutLoader>('routes/_c');

  return (
    <Suspense fallback={<BellSkeleton />}>
      <Await
        errorElement={<BellSkeleton className="bg-red-200" />}
        resolve={initialData?.inboxItemEvents}
      >
        {(data) => (
          <ClientOnly fallback={<BellSkeleton />}>
            {() => <Events user={user} initialData={data} />}
          </ClientOnly>
        )}
      </Await>
    </Suspense>
  );
}

function Events({
  user,
}: {
  initialData: any;
  user: UserSession & { socketToken: string };
}) {
  const [open, setOpen] = React.useState(false);
  const [notificationPreferences, setNotificationsPreferences] = useNotificationPreferences();
  const [notificationSources, setNotificationsSources] = useNotificationSources();
  const [pushNotificationsEnabled, setPushNotificationsEnabled] = usePushNotificationsEnabled();

  const { data } = useInboxItemEvents({ user });

  function handleCheckedChange(notificationSource: NotificationSource) {
    return (_: boolean) => {
      setNotificationsSources(() => {
        let state: typeof notificationSources = Array.isArray(notificationSources)
          ? [...notificationSources]
          : ALL;

        if (state === ALL) state = [notificationSource];
        if (Array.isArray(notificationSources)) {
          if (!notificationSources.includes(notificationSource)) state.push(notificationSource);
          else {
            state = notificationSources.filter((p) => p !== notificationSource);
            if (!state.length) state = ALL;
          }
        }

        return state;
      });
    };
  }

  const events = data?.filter((e) =>
    filterInboxItemEvent(e, notificationPreferences, notificationSources, user),
  );

  const newEvent = events?.some((event) =>
    event.inboxItem.lastReadAtByViewer
      ? dayjs(event.createdAt).isAfter(dayjs(event.inboxItem.lastReadAtByViewer))
      : true,
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="size-8 overflow-visible rounded-full text-muted-foreground"
          suppressHydrationWarning
        >
          {newEvent ? (
            <span className="absolute right-0 top-0">
              <span className="relative flex size-2">
                <span className="absolute inline-flex size-full animate-ping rounded-full bg-red-400 opacity-75" />
                <span className="relative inline-flex size-2 rounded-full bg-red-500" />
              </span>
            </span>
          ) : null}
          <Bell className="size-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex max-h-[calc(100vh_/_2)] w-96 flex-col overflow-hidden p-0 md:mr-4">
        <header className="flex items-center justify-between border-b p-4">
          <span className="font-semibold tracking-wide text-foreground">
            Notifications
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="icon" variant="ghost" className="ml-2">
                  <Settings2 className="size-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="z-[9991]">
                <DropdownMenuLabel>Notifications Preferences</DropdownMenuLabel>
                <DropdownMenuRadioGroup
                  value={notificationPreferences}
                  onValueChange={(value) =>
                    setNotificationsPreferences(value as 'ALL' | 'REVELANT')
                  }
                >
                  <DropdownMenuRadioItem value="ALL">
                    All Notifications
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <InfoIcon className="ml-auto size-3 text-muted-foreground" />
                        </TooltipTrigger>
                      </Tooltip>
                      <TooltipContent>
                        Choosing 'All Notifications' means you'll receive all updates, which might
                        flood your inbox. Opt for a personalized experience to get relevant alerts.
                      </TooltipContent>
                    </TooltipProvider>
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value="REVELANT">
                    Revelant to me
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <InfoIcon className="ml-auto size-3 text-muted-foreground" />
                        </TooltipTrigger>
                        <TooltipContent>
                          Selecting 'Relevant to Me' delivers notifications assigned to you and new,
                          unassigned updates, streamlining your alerts for a focused experience.
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>

                <DropdownMenuSeparator />

                <DropdownMenuLabel className="flex items-center">
                  Notifications Sources
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <InfoIcon className="ml-auto size-3 text-muted-foreground" />
                      </TooltipTrigger>
                      <TooltipContent>
                        Select the source types that trigger notifications. For instance, choosing
                        'Flagged Messages' alerts you specifically for flagged content.
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </DropdownMenuLabel>
                <DropdownMenuCheckboxItem
                  checked={notificationSources === 'ALL'}
                  onCheckedChange={() => setNotificationsSources('ALL')}
                >
                  All
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={notificationSources?.includes('ACTIVITY_SUBMITTED_FOR_VALIDATION')}
                  onCheckedChange={handleCheckedChange('ACTIVITY_SUBMITTED_FOR_VALIDATION')}
                >
                  Activity submitted for validation
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={notificationSources?.includes('ACTIVITY_AUTO_ACCEPTED')}
                  onCheckedChange={handleCheckedChange('ACTIVITY_AUTO_ACCEPTED')}
                >
                  Activity auto-accepted
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={notificationSources?.includes('RECURRING_ACTIVITY_INSTANCE_CREATED')}
                  onCheckedChange={handleCheckedChange('RECURRING_ACTIVITY_INSTANCE_CREATED')}
                >
                  Recurring activity created
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={notificationSources?.includes('NEGATIVE_ACTIVITY_REVIEW_CREATED')}
                  onCheckedChange={handleCheckedChange('NEGATIVE_ACTIVITY_REVIEW_CREATED')}
                >
                  Negative activity review
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={notificationSources?.includes(
                    'ACTIVITY_ACTIVE_SEAT_ALERT_COUNT_UPDATED',
                  )}
                  onCheckedChange={handleCheckedChange('ACTIVITY_ACTIVE_SEAT_ALERT_COUNT_UPDATED')}
                >
                  5-seat alert reached
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={notificationSources?.includes('SUPPORT_MESSAGE_SENT')}
                  onCheckedChange={handleCheckedChange('SUPPORT_MESSAGE_SENT')}
                >
                  Colette Bot message
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={notificationSources?.includes('MESSAGE_FLAGGED')}
                  onCheckedChange={handleCheckedChange('MESSAGE_FLAGGED')}
                >
                  Flagged Messages
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={notificationSources?.includes('MESSAGE_REPORTED')}
                  onCheckedChange={handleCheckedChange('MESSAGE_REPORTED')}
                >
                  Reported Messages
                </DropdownMenuCheckboxItem>

                <DropdownMenuSeparator />

                <DropdownMenuCheckboxItem
                  checked={!!pushNotificationsEnabled}
                  onCheckedChange={(enabled) => {
                    setPushNotificationsEnabled(enabled);

                    if (enabled && Notification.permission !== 'granted') {
                      Notification.requestPermission();
                    }
                  }}
                >
                  Push notifications
                </DropdownMenuCheckboxItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </span>
          <Link
            to="/inbox"
            className={buttonVariants({ variant: 'outline', size: 'sm' })}
            onClick={() => setOpen(false)}
            prefetch="intent"
          >
            Inbox
          </Link>
        </header>

        <main className="divide-y divide-border overflow-y-auto">
          {events?.length ? (
            events.map((inboxItem) => (
              <Event key={inboxItem.id} event={inboxItem} onClick={() => setOpen(false)} />
            ))
          ) : (
            <p className="p-4 text-center text-muted-foreground">No notifications yet</p>
          )}
        </main>
      </PopoverContent>
    </Popover>
  );
}

function Event({
  event,
  onClick,
}: {
  event: InboxItemEvent;
  onClick?: () => void;
}) {
  const navigate = useNavigate();

  const isRead = event.inboxItem.lastReadAtByViewer
    ? dayjs(event.createdAt).isBefore(dayjs(event.inboxItem.lastReadAtByViewer))
    : false;

  return (
    <article
      onClick={() => {
        navigate(getNotificationLink(event));
        onClick?.();
      }}
      className="group relative flex cursor-pointer items-start gap-4 px-4 py-3.5 transition-all duration-300 ease-in-out hover:bg-slate-50"
    >
      <PrefetchPageLinks page={getNotificationLink(event)} />
      <div className="flex flex-1 flex-col gap-1 overflow-hidden">
        <div className="flex flex-col justify-between">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <InboxItemName sourceEvent={event.inboxItem.sourceEvent} />
              {isRead ? null : (
                <span className="relative flex size-2">
                  <span className="absolute inline-flex size-full animate-ping rounded-full bg-foreground opacity-75" />
                  <span className="relative inline-flex size-2 rounded-full bg-foreground" />
                </span>
              )}
            </div>
            <ItemEventIcon sourceEvent={event.inboxItem.sourceEvent} />
          </div>

          <EventBody event={event} />

          <div className="mt-3.5 flex items-center justify-between">
            <TriggeredBy event={event} />
            <small className="text-[10px] text-muted-foreground">
              {dayjs(event.createdAt).fromNow()}
            </small>
          </div>
        </div>
      </div>
    </article>
  );
}

function TriggeredBy({ event }: { event: InboxItemEvent }) {
  const { event: eventName } = event;

  if (!event.triggerUser) return <span className="text-xs">Unassigned</span>;

  return (
    <div className="flex items-center text-xs first-letter:uppercase">
      <Avatar user={event.triggerUser} disableRing size="2xs" className="mr-2" />
      {capitalize(eventName.split('_').pop()?.toLowerCase())} by {formatUser(event.triggerUser)}
    </div>
  );
}

function EventBody({ event }: { event: InboxItemEvent }) {
  const body = getEventBody(event);

  return (
    <div className="relative mt-2 flex truncate">
      <p className="block w-full cursor-pointer gap-2 truncate pr-2 text-sm">
        <span className="mr-2">
          {event.inboxItem.activity?.areas.map((area) => AREA_EMOJI[area.value])}
        </span>
        {body}
      </p>
    </div>
  );
}
