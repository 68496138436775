import { cn } from '~/utils/cn';
import { stringToColor } from '~/utils/color';

interface Props extends React.ComponentPropsWithRef<'span'> {
  variant:
    | 'green'
    | 'red'
    | 'black'
    | 'yellow'
    | 'blue'
    | 'pink'
    | 'orange'
    | 'gray'
    | 'auto'
    | 'rose'
    | 'lime';
  suffix?: React.ElementType;
}

export function Badge({ ref, variant = 'black', children, suffix: Suffix, ...props }: Props) {
  return (
    <span
      {...props}
      ref={ref}
      style={
        variant === 'auto'
          ? {
              backgroundColor: stringToColor(children?.toString() || '', 0.05),
              color: stringToColor(children?.toString() || ''),
            }
          : {}
      }
      className={cn(
        'inline-flex h-5 items-center rounded px-2 py-0.5 text-xs font-medium leading-none text-foreground transition-colors',
        {
          'bg-foreground/60 text-background': variant === 'black',
          'bg-muted text-foreground': variant === 'gray',
          'bg-red-50 text-red-800': variant === 'red',
          'bg-yellow-50 text-yellow-800': variant === 'yellow',
          'bg-blue-50 text-blue-800': variant === 'blue',
          'bg-green-50 text-green-800': variant === 'green',
          'bg-pink-50 text-pink-800': variant === 'pink',
          'bg-orange-50 text-orange-800': variant === 'orange',
          'bg-rose-50 text-rose-800': variant === 'rose',
          'bg-lime-50 text-lime-800': variant === 'lime',
          'brightness-75': variant === 'auto',
        },
        props.className,
      )}
    >
      {children}
      {Suffix ? <Suffix className="ml-1 size-3" /> : null}
    </span>
  );
}

Badge.displayName = 'Badge';
