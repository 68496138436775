import type { UserAvatarFragment } from '~/generated/club.server';
import type { InboxItemEvent } from './use-inbox-item-events';
import type { loader } from './route';

export type InboxItem = NonNullable<Awaited<ReturnType<typeof loader>>['inboxItems']>[number];

export function getNotificationLink(item: InboxItemEvent | { inboxItem: InboxItem }) {
  const { activityReview, message, activity, id } = item.inboxItem;

  let to = '';
  if (activityReview) {
    to = `/activities/${activityReview.activity.id}/reviews`;
  } else if (message) {
    to = `/discussions/threads/${message.thread.id}`;
  } else if (activity) {
    to = `/activities/${activity.id}`;
  }

  to += `?inboxItemId=${id}`;

  if (message) to += `#${message.id}`;

  return to;
}

export function getEventBody(event: InboxItemEvent | { inboxItem: InboxItem }) {
  let body: string | null | undefined = null;

  if (event.inboxItem.activity) body = event.inboxItem.activity.name;
  else if (event.inboxItem.message) body = event.inboxItem.message.body;
  else if (event.inboxItem.activityReview) {
    body = event.inboxItem.activityReview.activity.name;
  }

  return body;
}

export function getEventAuthor(event: InboxItemEvent | { inboxItem: InboxItem }) {
  let author: UserAvatarFragment | null = null;
  if (event.inboxItem.activity?.creator)
    author = event.inboxItem.activity?.creator as UserAvatarFragment;
  if (event.inboxItem.message?.sender) author = event.inboxItem.message.sender;
  if (event.inboxItem.activityReview?.user) author = event.inboxItem.activityReview?.user;

  return author;
}
