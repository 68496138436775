import { cn } from '~/utils/cn';

export function Kbd(props: React.ComponentPropsWithRef<'kbd'>) {
  return (
    <kbd
      {...props}
      className={cn(
        'pointer-events-none inline-flex h-5 select-none items-center gap-1 self-start rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100',
        props.className,
      )}
    />
  );
}
