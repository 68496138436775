export function stringToColor(str: string, opacity = 1) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const color: number[] = [];
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color.push(value);
  }

  color.push(opacity);

  return 'rgba(' + color.join(',') + ')';
}
