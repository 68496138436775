import React from 'react';
import Uppy from '@uppy/core';
import Transloadit from '@uppy/transloadit';
import { v4 as uuid } from 'uuid';
import { useUppy } from '~/hooks/use-uppy';

const MAX_FILE_SIZE_ALLOWED = 8.2e8; // 820MB

export function useMedias(id = 'NEW') {
  const [toDeleteMediaIds, setMediaIdsToDeleted] = React.useState<string[]>([]);
  const videoUploader = useVideoUpload(id);
  const pictureUploader = usePicturesUpload(id);

  function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const files = e.target.files;

    if (!files) return;

    for (const file of files) {
      if (file.type.startsWith('image/')) {
        pictureUploader.uppy.addFile({ data: file, name: file.name, type: file.type });
      } else if (file.type.startsWith('video/')) {
        if (file.size > MAX_FILE_SIZE_ALLOWED) {
          alert(`${file.name} is too large. Max file size allowed is 820MB`);
        } else {
          videoUploader.uppy.addFile({
            data: file,
            name: file.name,
            type: file.type,
            meta: { id: uuid() },
          });
        }
      }
    }
  }

  function handleDelete(id: string) {
    if (id.startsWith('uppy')) {
      pictureUploader.uppy.removeFile(id);
      videoUploader.uppy.removeFile(id);
    } else setMediaIdsToDeleted((p) => [...p, id]);
  }

  return {
    videoUploader,
    pictureUploader,
    handleUpload,
    handleDelete,
    toDeleteMediaIds,
    isUploading: pictureUploader.status === 'loading' || videoUploader.status === 'loading',
  };
}

function useVideoUpload(id: string) {
  return useUppy(() => {
    const uppy = new Uppy<{ ssl_url?: string; id: string }, any>({
      id: 'VIDEO-' + id,
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ['video/*'],
      },
    }).on('restriction-failed', (_, e) => alert(e.message));

    uppy
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        waitForEncoding: false,
        waitForMetadata: true,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        limit: 1,
        assemblyOptions() {
          return {
            params: {
              auth: {
                key: import.meta.env.VITE_TRANSLOADIT_KEY,
              },
              template_id: import.meta.env.VITE_TRANSLOADIT_MESSENGER_VIDEO_TEMPLATE,
              fields: {
                id: uppy.getState().meta.id,
              },
            },
          };
        },
      })
      .on('transloadit:upload', async (upload, assembly) => {
        const file = uppy.getFiles().find((f) => f.name === upload.name);

        if (file) {
          uppy.setFileMeta(file.id, { ssl_url: upload.ssl_url, id: assembly.fields.id });
        }
      });

    return uppy;
  });
}

const MAX_NUMBER_OF_FILES = 4;

function usePicturesUpload(id: string) {
  const [, forceUpdate] = React.useState(0);
  return useUppy(() => {
    const uppy = new Uppy<{ ssl_url?: string }>({
      id: 'PICTURE-' + id,
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ['image/*'],
        maxNumberOfFiles: MAX_NUMBER_OF_FILES,
      },
    })
      .on('restriction-failed', (_, e) => alert(e.message))

      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        assemblyOptions: {
          params: {
            auth: {
              key: import.meta.env.VITE_TRANSLOADIT_KEY,
            },
            template_id: import.meta.env.VITE_TRANSLOADIT_MESSENGER_TEMPLATE,
          },
        },
        waitForEncoding: true,
        waitForMetadata: true,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        limit: 1,
      })
      .on('transloadit:result', async (_, result) => {
        if (result.localId) {
          uppy.setFileMeta(result.localId, { ssl_url: result.ssl_url });
          forceUpdate((p) => p + 1);
        }
      });

    return uppy;
  });
}
