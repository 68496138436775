export function formatUser(
  user?:
    | {
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        badge?: string | null | undefined;
      }
    | null
    | undefined,
) {
  return [user?.badge, user?.firstName, user?.lastName].filter(Boolean).join(' ');
}
