import type { InboxItemSourceEvent } from '~/generated/club.server';

export const INBOX_ITEM_NAME: Record<InboxItemSourceEvent, string> = {
  ACTIVITY_SUBMITTED_FOR_VALIDATION: 'Activity awaiting validation',
  MESSAGE_FLAGGED: 'Message flagged',
  MESSAGE_REPORTED: 'Message reported',
  NEGATIVE_ACTIVITY_REVIEW_CREATED: 'Negative activity review',
  SUPPORT_MESSAGE_SENT: 'Colette Bot message',
  ACTIVITY_ACTIVE_SEAT_ALERT_COUNT_UPDATED: '5-seat alert reached',
  RECURRING_ACTIVITY_INSTANCE_CREATED: 'Recurring activity created',
  ACTIVITY_AUTO_ACCEPTED: 'Activity auto-accepted',
  UNKNOWN: 'Unknown event',
};

export function InboxItemName({ sourceEvent }: { sourceEvent: InboxItemSourceEvent }) {
  return (
    <span className="inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold text-foreground transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
      {INBOX_ITEM_NAME[sourceEvent]}
    </span>
  );
}
