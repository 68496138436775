import { createElement } from 'react';
import { cn } from '~/utils/cn';
import type { ErrorMessage } from './types';
import { useFieldValidation } from './use-field-validation';
import { Label } from '../Label';

export const useInputContainerProps = <
  P extends Omit<Props, 'error'> & {
    errorMessages?: Partial<ErrorMessage>;
    onInput?: (e: any) => any;
    onInvalid?: (e: any) => any;
  },
>({
  as,
  label,
  helper,
  required,
  errorMessages,
  containerClassName,
  ...props
}: P) => {
  const { error, ...fieldValidation } = useFieldValidation(errorMessages);

  return {
    inputContainerProps: {
      required,
      as,
      label,
      error,
      helper,
      containerClassName,
    },
    ...props,
    required,
    ...fieldValidation.props,
    onInvalid: (e: React.FormEvent<any>) => {
      props.onInvalid?.(e);
      fieldValidation.props.onInvalid(e);
    },
    onInput: (e: React.FormEvent<any>) => {
      props.onInput?.(e);
      fieldValidation.props.onInput(e);
    },
  };
};

interface Props {
  label?: React.ReactNode;
  required?: boolean;
  error?: React.ReactNode;
  as?: React.ElementType;
  helper?: React.ReactNode;
  containerClassName?: string;
}

export const InputContainer = ({
  ref,
  children,
  label,
  required,
  error,
  as = Label,
  helper,
  containerClassName,
  ...props
}: Props & React.ComponentPropsWithRef<'fieldset'>) => {
  return (
    <>
      <fieldset {...props} className={cn(props.className, containerClassName)} ref={ref}>
        {label || !required ? (
          <legend className="mb-4 flex w-full items-center justify-between text-xs text-foreground">
            {label ? createElement(as, { className: 'block' }, label) : null}

            {required ? null : <span className="ml-auto block">Optional</span>}
          </legend>
        ) : null}

        {children}

        {error ? <p className="mt-2 text-sm text-red-500">{error}</p> : null}
        {helper && !error ? <p className="mt-1 text-xs text-muted-foreground">{helper}</p> : null}
      </fieldset>
    </>
  );
};

InputContainer.displayName = 'InputContainer';
