import type { Badge } from '~/components/Badge';
import type { LucideIcon } from 'lucide-react';
import { Archive, BadgeCheck, CalendarCheck, CircleDotDashed, XCircle } from 'lucide-react';
import type { ActivityStatuses } from '~/generated/club.server';

export function getVariantFromStatus(status: ActivityStatuses): {
  color: React.ComponentPropsWithRef<typeof Badge>['variant'];
  icon: LucideIcon;
  className: string;
} {
  switch (status) {
    case 'ACCEPTED':
      return { color: 'blue', icon: BadgeCheck, className: 'bg-blue-500' };
    case 'ARCHIVED':
      return { color: 'auto', icon: Archive, className: 'bg-muted-foreground' };
    case 'DRAFT':
    case 'SUBMITTED_FOR_VALIDATION':
      return { color: 'black', icon: CircleDotDashed, className: 'bg-foreground' };
    case 'PUBLISHED':
      return { color: 'green', icon: CalendarCheck, className: 'bg-green-500' };
    case 'REJECTED':
      return { color: 'red', icon: XCircle, className: 'bg-red-500' };
    default:
      return { color: 'gray', icon: CircleDotDashed, className: 'bg-muted' };
  }
}

export function getTextHelperFromStatus(status: ActivityStatuses) {
  switch (status) {
    case 'ACCEPTED':
      return 'Accepted but not yet published.';
    case 'ARCHIVED':
      return 'Archived by the team.';
    case 'DRAFT':
      return 'Activity created from the mobile app but not yet submitted for validation.';
    case 'SUBMITTED_FOR_VALIDATION':
      return 'Activity submitted for validation from the mobile app, waiting for the team to accept or reject it.';
    case 'PUBLISHED':
      return 'Published activity because the publication date is in the past.';
    case 'REJECTED':
      return 'Activity rejected by the team.';
  }
}
