import { cn } from '~/utils/cn';
import type { InputSize } from './types';

interface Props extends Omit<React.ComponentProps<'span'>, 'content'> {
  content?: React.ElementType;
  size?: InputSize;
}

const Xfix = ({ content: Content, size, ...props }: Props) => {
  if (!Content) return null;

  return (
    <span
      {...props}
      className={cn(
        props.className,
        'absolute inset-y-0 grid place-content-center place-items-center text-muted-foreground',
        { 'px-3': size === 'md', 'px-2': size === 'sm' },
      )}
    >
      <Content
        className={cn({
          'h-4 w-4': size === 'md',
          'h-3 w-3': size === 'sm',
        })}
      />
    </span>
  );
};

export const Prefix = (props: Props) => (
  <Xfix {...props} className={cn(props.className, 'left-0')} />
);

export const Suffix = (props: Props) => (
  <Xfix {...props} className={cn(props.className, 'right-0')} />
);
