export function formatPrice(
  priceInCents: number,
  options: Intl.NumberFormatOptions = {},
  locale = 'fr-FR',
) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    ...options,
    currency: options.currency || 'EUR',
  }).format(priceInCents / 100);
}

export function formatPercent(
  value: number,
  options: Intl.NumberFormatOptions = {},
  locale = 'fr-FR',
) {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumSignificantDigits: 2,
    ...options,
  }).format(value / 100);
}

export function convertToStars(score: number): number | null {
  if (score < 0 || score > 100) return null;

  const stars = (score / 100) * 5;
  return Number.parseFloat(stars.toFixed(1)); // Limite à une décimale
}

export function formatNumber(
  value: number,
  options: Intl.NumberFormatOptions = {},
  locale = 'fr-FR',
) {
  return new Intl.NumberFormat(locale, options).format(value);
}
