import { Slot } from '@radix-ui/react-slot';
import { cn } from '~/utils/cn';

interface Props extends React.ComponentPropsWithRef<'div'> {}

export function Layout(props: Props) {
  return <div {...props} className={cn('pb-4', props.className)} />;
}

export function LayoutHeader({ children, ...props }: React.ComponentPropsWithRef<'header'>) {
  return (
    <header {...props} className="mb-6 border-b px-4 py-8">
      <div className={cn('flex items-center justify-between gap-4', props.className)}>
        {children}
      </div>
    </header>
  );
}

export function LayoutHeaderContent(props: React.ComponentPropsWithRef<'div'>) {
  return <div {...props} className={cn('flex flex-col gap-2', props.className)} />;
}

export function LayoutTitle(props: React.ComponentPropsWithRef<'h1'>) {
  return (
    <h1
      {...props}
      className={cn(
        'text-2xl font-medium tracking-tighter first-letter:capitalize',
        props.className,
      )}
    />
  );
}

export function LayoutDescription(props: React.ComponentPropsWithRef<'div'>) {
  return (
    <div {...props} className={cn('space-y-1 text-sm text-muted-foreground', props.className)} />
  );
}

export function LayoutExtra(props: React.ComponentPropsWithRef<'div'>) {
  return <div {...props} className={cn('flex items-center gap-4', props.className)} />;
}

export function Stats(props: React.ComponentPropsWithRef<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        'flex flex-wrap gap-x-10 gap-y-2 self-start rounded-md border bg-background p-2.5 text-sm',
        props.className,
      )}
    />
  );
}

export const Stat = ({ ref, ...props }: React.ComponentPropsWithRef<'div'>) => {
  return (
    <div {...props} className={cn('flex flex-col gap-y-1 text-xs', props.className)} ref={ref} />
  );
};

Stat.displayName = 'Stat';

export function StatTitle(props: React.ComponentPropsWithRef<'span'>) {
  return <span {...props} className={cn('text-muted-foreground', props.className)} />;
}

export function StatContent({
  asChild,
  ...props
}: React.ComponentPropsWithRef<'div'> & { asChild?: boolean }) {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp {...props} className={cn('whitespace-nowrap font-medium capitalize', props.className)} />
  );
}
