// Related to https://github.com/transloadit/uppy/blob/main/packages/%40uppy/react/src/useUppy.js
import React from 'react';
import { Uppy, type UppyEventMap, type State } from '@uppy/core';
import type { Body, Meta } from '@uppy/utils/lib/UppyFile';
import { useMemo, useCallback } from 'react';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector.js';

export const useUppy = <T extends Uppy<any, any>>(factory: () => T) => {
  const [uppy, setUppy] = React.useState<T | undefined>(undefined);
  const [uploadStatus, setUploadState] = useUploadStatus();

  React.useEffect(() => {
    if (uppy === undefined) {
      const result = factory()
        .on('file-added', () => setUploadState({ status: 'loading' }))
        .on('progress', () => setUploadState({ status: 'loading' }))
        .on('error', (e) => setUploadState({ status: 'failed', message: e.message }))
        .on('restriction-failed', (_, e) =>
          setUploadState({ status: 'failed', message: e.message }),
        )
        .on('upload-error', (_file, e) => setUploadState({ status: 'failed', message: e.message }))
        .on('transloadit:upload', async () => setUploadState({ status: 'success' }));

      if (!(result instanceof Uppy)) {
        throw new TypeError(
          `useUppy: factory function must return an Uppy instance, got ${typeof uppy}`,
        );
      }

      setUppy(result);
    }
  }, [factory, uppy, setUploadState]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: it's ok
  React.useEffect(() => {
    return () => {
      if (uppy != null) {
        uppy.resetProgress();
        setUploadState({ status: 'idle' });
      }
    };
  }, [uppy]);

  return { uppy: uppy as T, ...uploadStatus };
};

export type UploadStatus =
  | { status: 'idle' | 'loading' | 'success' }
  | {
      status: 'failed';
      message: string;
    };

const useUploadStatus = () => React.useState<UploadStatus>({ status: 'idle' });

export function useUppyUploadStatus<M extends Meta = Meta, B extends Body = Body>(
  uppy: Uppy<M, B>,
) {
  const [status, setStatus] = React.useState<UploadStatus>({ status: 'idle' });

  useUppyEvent(uppy, 'file-added', () => {
    setStatus({ status: 'loading' });
  });

  useUppyEvent(uppy, 'progress', () => {
    setStatus({ status: 'loading' });
  });

  useUppyEvent(uppy, 'transloadit:result', () => {
    setStatus({ status: 'success' });
  });

  return [status, setStatus];
}

export function useUppyState<M extends Meta = Meta, B extends Body = Body, T = any>(
  uppy: Uppy<M, B>,
  selector: (state: State<M, B>) => T,
): T {
  const subscribe = useMemo(() => uppy.store.subscribe.bind(uppy.store), [uppy.store]);
  const getSnapshot = useCallback(() => uppy.store.getState(), [uppy.store]);

  return useSyncExternalStoreWithSelector(subscribe, getSnapshot, getSnapshot, selector);
}

type EventResults<M extends Meta, B extends Body, K extends keyof UppyEventMap<M, B>> = Parameters<
  UppyEventMap<M, B>[K]
>;

export function useUppyEvent<M extends Meta, B extends Body, K extends keyof UppyEventMap<M, B>>(
  uppy: Uppy<M, B>,
  event: K,
  callback?: (...args: EventResults<M, B, K>) => void,
): [EventResults<M, B, K> | [], () => void] {
  const [result, setResult] = React.useState<EventResults<M, B, K> | []>([]);
  const clear = () => setResult([]);

  React.useEffect(() => {
    const handler = ((...args: EventResults<M, B, K>) => {
      setResult(args);
      callback?.(...args);
    }) as UppyEventMap<M, B>[K];

    uppy.on(event, handler);

    return function cleanup() {
      uppy.off(event, handler);
    };
  }, [uppy, event, callback]);

  return [result, clear];
}
