import { Link } from '@remix-run/react';
import { cn } from '~/utils/cn';

export const A = ({ ref, ...props }: React.ComponentPropsWithRef<typeof Link>) => {
  function handleClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.stopPropagation();

    props.onClick?.(e);
  }

  return (
    <Link
      prefetch="intent"
      {...props}
      className={cn('hover:underline', props.className)}
      ref={ref}
      onClick={handleClick}
    />
  );
};

A.displayName = 'a';
