import { Bot, Flag, Repeat, SmileIcon, SmilePlus, Sticker, ThumbsDown } from 'lucide-react';
import type { InboxItemSourceEvent } from '~/generated/club.server';
import { cn } from '~/utils/cn';

const ITEM_EVENT_ICONS: Record<InboxItemSourceEvent, any> = {
  ACTIVITY_AUTO_ACCEPTED: {
    icon: Sticker,
    color: 'text-blue-500',
    background: 'bg-blue-100',
    border: 'border-blue-50',
  },
  ACTIVITY_ACTIVE_SEAT_ALERT_COUNT_UPDATED: {
    icon: SmileIcon,
    color: 'text-blue-500',
    background: 'bg-blue-100',
    border: 'border-blue-50',
  },
  RECURRING_ACTIVITY_INSTANCE_CREATED: {
    icon: Repeat,
    color: 'text-blue-500',
    background: 'bg-blue-100',
    border: 'border-blue-50',
  },
  ACTIVITY_SUBMITTED_FOR_VALIDATION: {
    icon: SmilePlus,
    color: 'text-blue-500',
    background: 'bg-blue-100',
    border: 'border-blue-50',
  },
  MESSAGE_FLAGGED: {
    icon: Flag,
    color: 'text-orange-500',
    background: 'bg-orange-100',
    border: 'border-orange-50',
  },
  MESSAGE_REPORTED: {
    icon: Flag,
    color: 'text-orange-500',
    background: 'bg-orange-100',
    border: 'border-orange-50',
  },
  NEGATIVE_ACTIVITY_REVIEW_CREATED: {
    icon: ThumbsDown,
    color: 'text-red-500',
    background: 'bg-red-100',
    border: 'border-red-50',
  },
  SUPPORT_MESSAGE_SENT: {
    icon: Bot,
    color: 'text-blue-500',
    background: 'bg-blue-100',
    border: 'border-blue-50',
  },
  UNKNOWN: {
    icon: Bot,
    color: 'text-blue-500',
    background: 'bg-blue-100',
    border: 'border-blue-50',
  },
};

export function ItemEventIcon({
  sourceEvent,
  className,
}: {
  sourceEvent: InboxItemSourceEvent;
  className?: string;
}) {
  const icon = ITEM_EVENT_ICONS[sourceEvent];
  const IconComp = icon.icon;

  return (
    <div
      className={cn(
        'flex aspect-square h-6 w-6 items-center justify-center rounded-full',
        icon.background,
        icon.border,
        className,
      )}
    >
      <IconComp className={cn('h-3 w-3', icon.color)} />
    </div>
  );
}

export function InboxItemIcon({
  sourceEvent,
  readByViewer,
}: {
  sourceEvent: InboxItemSourceEvent;
  readByViewer: boolean;
}) {
  const icon = ITEM_EVENT_ICONS[sourceEvent];
  const IconComp = icon.icon;

  return (
    <span className="relative flex size-5">
      {readByViewer ? null : (
        <span className="absolute left-0.5 top-0.5 inline-flex size-[calc(100%_-_4px)] animate-ping rounded-full bg-foreground" />
      )}
      <span
        className={cn(
          'relative flex aspect-square h-5 w-5 items-center justify-center rounded-full',
          icon.background,
          icon.border,
        )}
      >
        <IconComp className={cn('relative inline-flex h-2.5 w-2.5', icon.color)} />
      </span>
    </span>
  );
}
