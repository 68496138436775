export const computeCloudflareImageUrl = (src: string, params: string) =>
  `https://assets.colette.club/cdn-cgi/image/${params}/${normalizeSrc(src)}`;

const normalizeSrc = (src: string) => {
  return src[0] === '/' ? src.slice(1) : src;
};

const deviceSizes = [640, 750, 828, 1080, 1200, 1920, 2048, 3840];

interface Options {
  quality?: number;
  width?: number;
  height?: number;
}

const generateSrcSet = (u: string, opts: Options = {}) => {
  let src = u;
  let srcSet = '';

  if (u?.startsWith('/') || u?.startsWith('blob:')) {
    src = u;
  } else {
    srcSet = deviceSizes
      .map((d) => {
        const params = ['width=' + d];
        if (opts.quality) params.push('quality=' + opts.quality);

        return computeCloudflareImageUrl(src, params.join('&')) + ` ${d}w`;
      })
      .join(' ,');
  }

  return { src, srcSet };
};

export const Image = ({
  ref,
  quality,
  ...props
}: Omit<React.ComponentPropsWithRef<'img'>, 'height' | 'width' | 'alt'> & {
  quality?: number;
  height?: number;
  width?: number;
  alt: string;
}) => {
  const { src, srcSet } = props.src
    ? generateSrcSet(props.src, {
        quality,
        width: props.width,
        height: props.height,
      })
    : { src: '', srcSet: '' };

  // biome-ignore lint/a11y/useAltText: generic component
  return <img loading="lazy" ref={ref} {...props} src={src} srcSet={srcSet} sizes="100vw" />;
};

Image.displayName = 'Image';
