import React from 'react';
import * as Sentry from '@sentry/remix';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { toast } from 'sonner';
import type { LinksFunction } from '@remix-run/node';
import type { MetaFunction } from '@remix-run/react';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import { ServerError } from '~/components/ServerError';
import { Toaster } from '~/components/Toaster';
import { useRouteActionToasts } from './hooks/use-route-action-data';
import sonnerStyles from './sonner.css?url';
import styles from './tailwind.css?url';
import { getEnv } from './utils/env';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(timezone);

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'stylesheet', href: sonnerStyles },
  {
    rel: 'icon',
    href: '/favicon.svg',
    type: 'image/svg+xml',
  },
];

export const meta: MetaFunction<typeof loader> = () => {
  return [{ title: 'Colette' }, { property: 'og:title', content: 'Colette' }];
};

export async function loader() {
  return {
    ENV: getEnv(),
  };
}

const toasted: string[] = [];

function App() {
  const toasts = useRouteActionToasts();

  React.useEffect(() => {
    for (const _toast of toasts) {
      if (!toasted.includes(_toast.id)) {
        if (_toast.type === 'error') {
          console.error(_toast);

          toasted.push(_toast.id);
          toast.error(_toast.error.message, {
            duration: 15000,
          });
        }

        if (_toast.type === 'message') {
          toasted.push(_toast.id);
          toast(_toast.message, {
            duration: 1000,
            icon: (
              <svg viewBox="0 0 20 20" fill="currentColor" height="20" width="20">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            ),
          });
        }
      }
    }
  }, [toasts]);

  return <Outlet />;
}

export default App;

export function ErrorBoundary() {
  const routeError = useRouteError();

  Sentry.captureRemixErrorBoundaryError(routeError);

  return <ServerError error={routeError} />;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');

  return (
    <html lang="en" className="scroll-pt-28" suppressHydrationWarning>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <style data-fullcalendar />
        <Links />
        <Meta />
      </head>
      <body
        className="min-h-screen overflow-x-hidden bg-[#fafafa] font-sans antialiased"
        suppressHydrationWarning
      >
        {children}
        <Toaster richColors position="top-center" />
        <script
          // biome-ignore lint/security/noDangerouslySetInnerHtml: recommended by Remix
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data?.ENV)}`,
          }}
        />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
