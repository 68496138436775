import React from 'react';

const VideoPlayer = React.lazy(() => import('@mux/mux-player-react/lazy'));

type Props = React.ComponentPropsWithRef<typeof VideoPlayer>;

export function Video(props: Props) {
  return (
    <VideoPlayer
      preload="none"
      loading="viewport"
      streamType="on-demand"
      style={{ aspectRatio: 16 / 9 }}
      accentColor="#FFBE18"
      {...props}
    />
  );
}
