import React, { Suspense } from 'react';
import * as Sentry from '@sentry/remix';
import dayjs from 'dayjs';
import { toast } from 'sonner';
import { SWRConfig } from 'swr';
import type { LoaderFunctionArgs } from '@remix-run/node';
import { Await, Outlet, useLoaderData, useNavigate } from '@remix-run/react';
import { Header } from '~/components/Header';
import { Flame } from 'lucide-react';
import { InboxBanner } from '~/components/InboxBanner';
import { inboxItemEventsQuery, inboxItemQuery, usersQuery } from '~/generated/club.server';
import { requireViewerSession } from '~/utils/auth.server';
import { connectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { SearchParams } from '~/utils/search-params';
import { releaseCookie } from '~/utils/session.server';
// @ts-ignore
import { LAST_RELEASE } from './_c.releases._index.mdx';

export async function loader(ctx: LoaderFunctionArgs) {
  const { token, ...user } = await requireViewerSession(ctx.request);

  const releaseDate = await releaseCookie.parse(ctx.request.headers.get('Cookie') || '');

  const shouldShowRelease = !releaseDate || dayjs(releaseDate).isBefore(dayjs(LAST_RELEASE));

  const inboxItem = new SearchParams<{ inboxItemId?: string }>(ctx.request.url).get('inboxItemId');

  return {
    user,
    admins: usersQuery(ctx.request, {
      first: 40,
      filter: { admin: true },
    }).then((admins) => connectionResultToNodeArray(admins.users)),
    inboxItem: inboxItem
      ? inboxItemQuery(ctx.request, { id: inboxItem }).then((res) => res.inboxItem)
      : undefined,
    inboxItemEvents: inboxItemEventsQuery(ctx.request, {
      first: 50,
      filter: { events: ['INBOX_ITEM_ASSIGNED', 'INBOX_ITEM_CREATED'] },
    }).then((res) => connectionResultToNodeArray(res.inboxItemEvents)),
    shouldShowRelease,
  };
}

export default function Club() {
  const data = useLoaderData<typeof loader>();
  const navigate = useNavigate();

  React.useEffect(() => {
    Sentry.setUser({
      id: data.user.id,
      email: data.user.email,
      username: data.user.firstName + `${data.user.lastName || '[undefined]'}`,
    });
  }, [data.user.id, data.user.email, data.user.firstName, data.user.lastName]);

  React.useEffect(() => {
    if (data.shouldShowRelease && !location.href.includes('localhost')) {
      setTimeout(() => {
        toast('New Update Available!', {
          id: 'NEW_RELEASE',
          description: 'Click here to see what’s new.',
          position: 'bottom-right',
          duration: Number.POSITIVE_INFINITY,
          dismissible: false,
          action: {
            label: 'Release Note',
            onClick: () => navigate('/releases'),
          },
          icon: <Flame className="w-6 fill-foreground text-foreground" />,
        });
      });
    }
  }, [data.shouldShowRelease, navigate]);

  return (
    <>
      <Suspense fallback={<Header user={data.user} loading />}>
        <Await errorElement={<span>Error</span>} resolve={data.inboxItemEvents}>
          {(inboxItemEvents) => (
            <SWRConfig
              value={{
                provider: () => new Map([['$sub$inbox', { data: inboxItemEvents }]]),
              }}
            >
              {data.inboxItem ? (
                <Suspense fallback={null}>
                  <Await errorElement={<span>Error</span>} resolve={data.inboxItem}>
                    {(inboxItem) => <InboxBanner inboxItem={inboxItem} />}
                  </Await>
                </Suspense>
              ) : null}
              <Header user={data.user} />
            </SWRConfig>
          )}
        </Await>
      </Suspense>
      <div className="mx-auto h-full">
        <Outlet context={{ user: data.user }} />
      </div>
    </>
  );
}
