import React from 'react';
// @ts-expect-error no types
import * as withAbsintheSocket from '@absinthe/socket';
// @ts-expect-error no types
import { Socket as PhoenixSocket } from 'phoenix';
import useSWRSubscription from 'swr/subscription';
import { useLocalStorage } from 'usehooks-ts';
import type { loader as _cLayoutLoader } from '~/routes/_c';
import type { UserSession } from '~/types';
import { INBOX_ITEM_NAME } from './InboxItemName';
import { getEventBody, getNotificationLink } from './utils';

export type InboxItemEvent = Awaited<
  Awaited<ReturnType<typeof _cLayoutLoader>>['inboxItemEvents']
>[number];

export const ALL = 'ALL';
export type NotificationSource =
  | 'ACTIVITY_SUBMITTED_FOR_VALIDATION'
  | 'MESSAGE_FLAGGED'
  | 'MESSAGE_REPORTED'
  | 'NEGATIVE_ACTIVITY_REVIEW_CREATED'
  | 'SUPPORT_MESSAGE_SENT'
  | 'ACTIVITY_ACTIVE_SEAT_ALERT_COUNT_UPDATED'
  | 'RECURRING_ACTIVITY_INSTANCE_CREATED'
  | 'ACTIVITY_AUTO_ACCEPTED'
  | 'UNKNOWN';

type NOTIFICATIONS_PREFERENCES = 'ALL' | 'REVELANT';

export function useNotificationPreferences() {
  return useLocalStorage<NOTIFICATIONS_PREFERENCES>('notifications-preferences', ALL);
}

export function useNotificationSources() {
  return useLocalStorage<'ALL' | NotificationSource[]>('notifications-sources', ALL);
}

export function usePushNotificationsEnabled() {
  return useLocalStorage<boolean>('push-notifications', false);
}

export function filterInboxItemEvent(
  inboxItemEvent: InboxItemEvent,
  notificationPreferences: NOTIFICATIONS_PREFERENCES | undefined,
  notificationSources: 'ALL' | NotificationSource[] | undefined,
  user: UserSession,
) {
  return (
    (notificationPreferences === ALL
      ? true
      : inboxItemEvent.triggerUser == null || inboxItemEvent.triggerUser.id !== user.id) &&
    (notificationSources === ALL
      ? true
      : notificationSources?.includes(inboxItemEvent.inboxItem.sourceEvent) ||
        inboxItemEvent.inboxItem.sourceEvent === 'UNKNOWN') &&
    ['INBOX_ITEM_ASSIGNED', 'INBOX_ITEM_CREATED'].includes(inboxItemEvent.event)
  );
}

export function useNotification({ user }: { user: UserSession }) {
  const [pushNotificationsEnabled] = usePushNotificationsEnabled();
  const [notificationPreferences] = useNotificationPreferences();
  const [notificationSources] = useNotificationSources();

  function sendNotification(inboxItemEvent: InboxItemEvent) {
    if (
      Notification.permission === 'granted' &&
      pushNotificationsEnabled &&
      filterInboxItemEvent(inboxItemEvent, notificationPreferences, notificationSources, user)
    ) {
      const notification = new Notification(INBOX_ITEM_NAME[inboxItemEvent.inboxItem.sourceEvent], {
        body: getEventBody(inboxItemEvent) || undefined,
        icon: location.origin + '/notification.png',
        data: inboxItemEvent,
      });

      notification.onclick = (event) => {
        event.preventDefault();

        window.open(location.origin + getNotificationLink(inboxItemEvent), '_blank');
      };
    }
  }

  return { sendNotification };
}

export const INBOX_ITEM_KEY = 'inbox';

export function useInboxItemEvents({
  user,
}: {
  user: UserSession & { socketToken: string };
}) {
  const { sendNotification } = useNotification({ user });

  const [absintheSocket] = React.useState<withAbsintheSocket.AbsintheSocket<any>>(() => {
    const absintheSocket = withAbsintheSocket.create(
      new PhoenixSocket(window.ENV.SOCKET_URL, {
        params: {
          Authorization: `Bearer ${user.socketToken}`,
        },
      }),
    );

    return absintheSocket;
  });

  return useSWRSubscription<InboxItemEvent[], any, string>(INBOX_ITEM_KEY, (_key, { next }) => {
    const notifier = withAbsintheSocket.send(absintheSocket, {
      operation,
      variables: { topic: 'ALL' },
    });

    const observer: withAbsintheSocket.Observer<{ type: any }, InboxItemEvent> = {
      onError: (error: any) => next(error),
      // onStart: logEvent('open'),
      onResult: (result: { data: { inboxItemEvent: InboxItemEvent } }) => {
        const { inboxItemEvent } = result.data;

        sendNotification(inboxItemEvent);

        next(null, (p = []) => [inboxItemEvent].concat(p));
      },
      onAbort: (error: any) => next(error),
    };

    withAbsintheSocket.observe(absintheSocket, notifier, observer);

    return () => withAbsintheSocket.cancel(absintheSocket, notifier);
  });
}

const operation = `
subscription InboxItemEvent($topic: InboxItemSubscriptionEventTopics!) {
  inboxItemEvent(topic: $topic) {
    id
    triggerUser {
      id
      admin
      activatedAt
      bannedAt
      rejectedAt
      deletedAt
      firstName
      lastName
      type
      communityVettingStatus
      phoneNumber
      email
      gender
      avatar {
        url
      }
    }
    targetUser {
      id
      admin
      activatedAt
      bannedAt
      rejectedAt
      deletedAt
      firstName
      lastName
      type
      communityVettingStatus
      phoneNumber
      email
      gender
      avatar {
        url
      }
    }
    inboxItem {
      id
      sourceType
      sourceEvent
      message {
        thread {
          id
          name
        }
        id
        body
        sender {
          id
          admin
          activatedAt
          bannedAt
          rejectedAt
          deletedAt
          firstName
          lastName
          type
          communityVettingStatus
          phoneNumber
          email
          gender
          avatar {
            url
          }
        }
      }
      activity {
        id
        name
        startTime
        picture {
          url
        }
        areas {
          value
        }
        creator {
          id
          admin
          activatedAt
          bannedAt
          rejectedAt
          deletedAt
          firstName
          lastName
          type
          communityVettingStatus
          phoneNumber
          email
          gender
          avatar {
            url
          }
        }
      }
      activityReview {
        rating
        user {
          id
          admin
          activatedAt
          bannedAt
          rejectedAt
          deletedAt
          firstName
          lastName
          type
          communityVettingStatus
          phoneNumber
          email
          gender
          avatar {
            url
          }
        }
        activity {
          id
          name
          picture {
            url
          }
        }
      }
    }
    event
    createdAt
    changes
  }
}
`;
