import type { GeographicArea } from '~/generated/club.server';

export const AREA_EMOJI: Record<GeographicArea['value'], string> = {
  PARIS: '🥖',
  LYON: '🦁',
  BORDEAUX: '🍷',
  MARSEILLE: '☀️',
  NICE: '⛵️',
  TOULOUSE: '🏉',
  NANTES: '🐘',
  RENNES: '🦌',
  ANGERS: '🏰',
  NANCY: '🍋',
  STRASBOURG: '🥨',
  REIMS: '👑',
  MONTPELLIER: '🙂',
  TOULON: '🐠',
  NIMES: '🏟️',
  AIXENPROVENCE: '🪻',
  LILLE: '🍻',
  OTHER: '🌍',
};

export function getAreaLabel(area?: GeographicArea | null | undefined, removeExtraText = false) {
  if (!area) return null;

  const areaName =
    area.value === 'OTHER'
      ? 'Other locality'
      : area.displayName.replace(/et sa région/gi, removeExtraText ? '' : 'and its region').trim();

  return `${AREA_EMOJI[area.value]} ${areaName}`;
}
